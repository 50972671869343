<template>
  <div>
    <confirm-modal ref="confirmModal" />

    <b-sidebar
      id="sidebar-file-upload"
      sidebar-class="sidebar-lg"
      :visible="uploadSideBarVisisble"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => (uploadSideBarVisisble = val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ T("Web.InvoicesPage.UploadBar.Header", "Upload invoice") }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- End Header -->
        <!-- Body -->
        <b-form class="p-2" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="T('Web.InvoicesPage.UploadBar.Files', 'Files')"
            label-for="account-number"
          >
            <b-form-file
              :placeholder="
                T('Web.InvoicesPage.UploadBar.Choosefile', 'Choose a file')
              "
              no-drop
              :browse-text="T('Web.Generic.Choose')"
              selected-format=".pdf"
              v-model="file"
              :multiple="false"
            />
          </b-form-group>
          <div class="d-flex mt-2 align-items-center">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="file == null || submitting"
            >
              {{
                submitting
                  ? T("Web.Generic.Uploading", "Uploading..")
                  : T("Web.Generic.Uploading", "Upload")
              }}
            </b-button>
          </div>
        </b-form>
        <!-- End Body -->
      </template>
    </b-sidebar>
    <b-card no-body>
      <div class="header p-2">
        <b-row>
          <b-col cols="6">
            <b-card-title>Filer</b-card-title>
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end align-items-center">
            <b-button
              variant="secondary"
              size="sm"
              @click="uploadSideBarVisisble = true"
              style="width: 120px"
              class="ml-1"
            >
              {{ T("Web.Generic.Upload") }}
              <feather-icon icon="UploadIcon" />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        show-empty
        :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
        :fields="tableFields"
        :items="tableItems"
      >
        <template #cell(uploadDate)="data">
          <small>{{ format_datetime(data.value) }}</small>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex justify-content-end">
            <b-button
              variant="danger"
              size="sm"
              class="mr-1"
              @click.prevent="removeFileAsync(data.item.id)"
              >Slet <feather-icon icon="TrashIcon"
            /></b-button>
            <b-button
              variant="secondary"
              size="sm"
              @click.prevent="donwloadFile(data.item.id, data.item.fileName)"
              >Download <feather-icon icon="DownloadIcon"
            /></b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BTable,
  BSidebar,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormFile,
} from "bootstrap-vue";
import ResourceService from "@/services/base/resource.service";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  components: {
    BSidebar,
    BCard,
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormFile,
    ConfirmModal,
  },
  data() {
    return {
      uploadSideBarVisisble: false,
      submitting: false,
      tableFields: [
        {
          key: "fileName",
          label: "Filnavn",
          sortable: true,
        },
        {
          key: "uploadDate",
          label: "Upload dato",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
      tableItems: [],
      file: null,
    };
  },
  async created() {
    await this.fetchFiles();
  },
  methods: {
    async fetchFiles() {
      const service = new ResourceService("file-drive");
      this.tableItems = (await service.get("")).data.data;
    },
    async handleSubmit() {
      const service = new ResourceService("file-drive");

      try {
        this.submitting = true;
        const formData = new FormData();
        formData.append("file", this.file, this.file.name);
        await service.post("", formData);
        this.uploadSideBarVisisble = false;
        this.file = null;
        await this.fetchFiles();
      } finally {
        this.submitting = false;
      }
    },
    async removeFileAsync(id) {
      if (!(await this.$refs.confirmModal.confirm())) return;
      const service = new ResourceService("file-drive");
      await service.delete(id);
      await this.fetchFiles();
    },
    async donwloadFile(id, filename) {
      const service = new ResourceService("file-drive");
      let filecontent = (await service.get(id)).data;


      const url = window.URL.createObjectURL(new Blob([filecontent], {type: 'application/pdf'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); // Specify the filename for the downloaded file
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    },
  },
};
</script>